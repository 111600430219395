<template>
  <div>
    <module-editor
      v-if="!loading"
      ref="editor"
      v-model="blogTemplate.content"
      crumb-title="Single Post"
      aside-title="Single Post"
      group="blocks"
      :draggable="true"
      @save="() => onSave({status_code: 'publish'})"
    >
      <template #save-text>
        Publish
      </template>

      <template #header-before>
        <button
          class="flex disabled:opacity-60 items-center rounded-left justify-center h-[36px] w-[41px] border bg-white p-0"
          :disabled="!isActiveUndo"
          @click="undo"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            d="M16.4649 10.7396C16.6349 10.704 16.7547 10.5567 16.7547 10.3867C16.7547 4.51518 9.7847 3.26446 8.16565 3.05318V0.35908C8.16565 0.226318 8.0928 0.10489 7.97541 0.0409374C7.85641 -0.0205864 7.71556 -0.0116817 7.60627 0.0603659L0.157034 5.07456C0.059082 5.14094 -1.52588e-05 5.25103 -1.52588e-05 5.37327C-1.52588e-05 5.49227 0.059082 5.60318 0.157034 5.66956L7.60222 10.6829C7.71313 10.7558 7.8556 10.7639 7.97218 10.7016C8.09037 10.6384 8.16322 10.5162 8.16322 10.3826V7.48451C9.3346 7.49179 10.3044 7.5436 11.1115 7.64075C14.9851 8.09975 16.0205 10.4255 16.0626 10.5251C16.12 10.6587 16.2512 10.7445 16.3945 10.7445C16.4187 10.7461 16.443 10.7445 16.4649 10.7396Z"
            fill="#6E747A"
          /></svg>
        </button>
        <button
          class="flex disabled:opacity-60 items-center rounded-right border-left-0 justify-center h-[36px] w-[41px] border bg-white p-0"
          :disabled="!isActiveRedo"
          @click="redo"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            d="M0.28981 10.7396C0.11981 10.704 0 10.5567 0 10.3867C0 4.51518 6.97 3.26446 8.58905 3.05318V0.35908C8.58905 0.226318 8.6619 0.10489 8.77929 0.0409374C8.89829 -0.0205864 9.03914 -0.0116817 9.14843 0.0603659L16.5977 5.07456C16.6956 5.14094 16.7547 5.25103 16.7547 5.37327C16.7547 5.49227 16.6956 5.60318 16.5977 5.66956L9.15248 10.6829C9.04157 10.7558 8.8991 10.7639 8.78252 10.7016C8.66433 10.6384 8.59148 10.5162 8.59148 10.3826V7.48451C7.4201 7.49179 6.45029 7.5436 5.64319 7.64075C1.76962 8.09975 0.734238 10.4255 0.692143 10.5251C0.634667 10.6587 0.503524 10.7445 0.360238 10.7445C0.335952 10.7461 0.311667 10.7445 0.28981 10.7396Z"
            fill="#6E747A"
          /></svg>
        </button>
      </template>

      <template #header-after>
        <div v-if="$route.params.post_id !== 'new' && post.slug">
          <b-btn
            v-if="post.status_code === 'publish'"
            variant="primary"
            :href="`https://${project.domain || project.subdomain + '.estage.site'}/blog/${post.slug}`"
            class="!text-[14px] ml-3"
            target="_blank"
          >
            View Post
          </b-btn>
          <b-btn
            v-else
            variant="primary"
            class="!text-[14px] ml-3"
            @click="() => onSave({status_code: 'draft'})"
          >
            Save Draft
          </b-btn>
        </div>

        <b-dropdown
          toggle-tag="div"
          variant="link"
          right
          toggle-class="border !border-[#E2E5EC] h-[37px] bg-white ml-3"
        >
          <template #button-content>
            <svg
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="8"
                cy="2"
                r="2"
                transform="rotate(-180 8 2)"
                fill="#6E747A"
              />
              <circle
                cx="14"
                cy="2"
                r="2"
                transform="rotate(-180 14 2)"
                fill="#6E747A"
              />
              <circle
                cx="2"
                cy="2"
                r="2"
                transform="rotate(-180 2 2)"
                fill="#6E747A"
              />
            </svg>
          </template>

          <div class="px-[29px] py-2 w-[223px]">
            <b-link
              class="block text-[#6E747A] text-[15px] text-decoration-none hover:text-[rgba(110,116,122,.8)]"
              @click="() => onSave({status_code: 'draft'})"
            >
              Save As Draft
            </b-link>
            <b-link
              class="block text-[#6E747A] text-[15px] text-decoration-none hover:text-[rgba(110,116,122,.8)]"
              @click="modal = true"
            >
              Save As Layout Template
            </b-link>
          </div>
        </b-dropdown>
      </template>

      <template #aside>
        <SidbearSettingsGroup
          title="Layout Templates"
          content-class="py-0"
        >
          <div class="overflow-auto  max-h-[470px] mr-[-21px] pr-[21px] pt-[14px] pb-[22px]">
            <div class="flex flex-col gap-[18px] w-100 align-items-center py-[18px]">
              <div
                v-for="(template, index) in templates"
                :key="template.id"
                class="shadow-sm h-[200px] overflow-hidden bg-center w-[200px] relative text-[14px] font-[700] flex justify-center items-center cursor-pointer transition bg-cover border-[1px] border-[#D7D7D7] rounded-[5px]"
                :class="{'border-[#4F83E3] border-[2px]': template.id === blogTemplate.id}"
                :style="{backgroundImage: `url(${template.screen})`}"
                @click="alertThenSelectTemplate(index)"
              >
                <span
                  v-if="template.id === 'blank'"
                  v-text="template.name"
                />
                <div
                  v-else
                  class="absolute bottom-0 text-white font-[700] text-[14px] left-0 h-[40px] bg-[rgba(0,0,0,.65)] flex items-center px-[12px] text-truncate w-[200px]"
                  v-text="template.name"
                />
              </div>
            </div>
          </div>
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Blocks"
          content-class="p-0"
        >
          <blocks-drag-items :filtered-blocks="['empty-blocks']" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Rows"
          :active="$store.state.editor.page === 'add-row'"
          content-class="p-0 no-scrollbar"
        >
          <rows-drag-items />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Elements"
          content-class="p-0"
          :active="$store.state.editor.page === 'add-element'"
        >
          <elements-drag-items editor="blog" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Dynamic Content"
          content-class="p-0"
        >
          <dynamic-elements-drag-items :group="['Dynamic Post Template']" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Favorites"
          content-class="p-0"
        >
          <favorites-drag-items class="border-top-0" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          ref="postSettingsTab"
          title="Post Settings"
          active
        >
          <div class="overflow-hidden">
            <post-settings
              ref="postSettings"
              v-model="post"
            />
          </div>
        </SidbearSettingsGroup>
      </template>

      <template v-if="!loadingRedoUndo">
        <content-drop-area
          v-if="blogTemplate.content"
          v-model="blogTemplate.content"
          :content-key="blogTemplate.id"
          drop-area-class="vh-70"
          group="blocks"
          add-group="blocks"
        />
      </template>
    </module-editor>
    <div
      v-else
      class="flex justify-center items-center vh-100"
    >
      <b-spinner variant="primary" />
    </div>

    <b-modal
      v-model="modal"
      hide-header
      hide-footer
      size="lg"
      centered
    >
      <div class="py-[40px] px-[90px]">
        <h5 class="text-[27px] font-[600] text-center">
          Save Layout Template
        </h5>
        <p class="text-center mb-4">
          (Single Post Page)
        </p>

        <b-form @submit.prevent="saveAsTemplate">
          <b-form-group
            label="Give your Template a name:"
            label-class="!text-[15px] !text-[#5B5B5B] !font-[400]"
            class="!mb-[35px]"
          >
            <b-input
              v-model="layoutName"
              required
            />
          </b-form-group>

          <modal-button
            :loading="modalLoading"
          >
            Save Layout Template
          </modal-button>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {toServerTime} from '@/utils/helpers'
import ResolutionMixin from '../../components/mixins/ResolutionMixin'
import {applyPageStyles, pageStylesToString} from '@/utils/useStylesheet'
import ViewportUpdate from '@/mixins/viewportUpdate'
import ContentDropArea from '@/components/editor/components/ContentDropArea.vue'
import ModuleEditor from '@/views/module-editor/ModuleEditor.vue'
import DynamicElementsDragItems from '@/components/editor/components/DynamicElementsDragItems.vue'
import BlocksDragItems from '@/components/editor/components/BlocksDragItems.vue'
import PostSettings from '@/views/blog/PostSettings.vue'
import ElementsDragItems from '@/components/editor/components/ElementsDragItems.vue'
import FavoritesDragItems from '@/components/editor/components/FavoritesDragItems.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import RowsDragItems from '@/components/editor/components/RowsDragItems.vue'
import ModalButton from '@/components/editor/components/ModalButton.vue'
import Screenshot from '@/mixins/Screenshot'
import UndoRedoSupport from '@/components/mixins/UndoRedoSupport'
import defaultTempaltes from './post-template/post-content-templates'
export default {
  name: 'PostEditor',
  components: {
    ModalButton,
    RowsDragItems,
    SidbearSettingsGroup,
    FavoritesDragItems,
    ElementsDragItems,
    PostSettings,
    BlocksDragItems,
    DynamicElementsDragItems, ModuleEditor, ContentDropArea},
  mixins: [ResolutionMixin, ViewportUpdate, Screenshot, UndoRedoSupport],
  provide () {
    return {
      getCustomPostEditorData: ()=> this.post,
      updateCustomPostEditorData: (key,val)=> {
        this.$set(this.post,key,val)
      }
    }
  },
  data () {
    return {
      subjectStyle: 'subject-styles',
      modal: false,
      layoutName: '',
      modalLoading: false,
      selectedTemplate: 0,
      loading: false,
      interval: null,
      settings: {
        hideHeader: false,
        hideFooter: false,
        sidebar: {
          enable: true,
          right: true
        }
      },
      templates: defaultTempaltes,
      favoriteBlock: {},
      blogTemplate: {},
      post: {
        allow_comment_guests: true,
        preview: {},
        'cover': '',
        'cover_thumb': '',
        'title': '',
        'description': '',
        'content': '',
        'code_injection': '',
        'status_code': 'draft',
        'fonts': '',
        'google_fonts': '',
        'custom_fonts': '',
        'categories': [],
        'author_id': null,
        'show_author': true,
        'show_date': true,
        'show_categories': true,
        'show_likes': true,
        'allow_comments': true,
        'allow_sharing': true,
        'seo_title': '',
        'post_date': '',
        'seo_keywords': '',
        'seo_description': '',
        'template_type': '',
        'template_id': 0,
        'seo_index':true,
        'comments_moderation':false
      }
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    getTargetContent: {
      get () {
        return this.blogTemplate.content
      },
      set (val) {
        this.$set(this.blogTemplate, 'content', val)
      }
    }
  },

  async beforeRouteLeave (to, from, next) {
    const answer = await this.$refs.editor.confirmLeave()
    if (!answer) return false
    next()
  },

  async created () {
    await this.fetchTemplates()

    this.$store.commit('editor/SET_PAGE', '')

    if (!this.post) {
      await this.$store.dispatch('blogEditor/startEdit', {
        id: this.$route.params.post_id
      })
    }

    this.$store.commit('historyChanges/SET_DISABLE', false)

    this.$store.state.editor.backLink = {
      name: 'settings.blog'
    }

    if (this.$route.params.post_id !== 'new') {
      axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${this.$route.params.post_id}`)
        .then(({data}) => {
          this.post = {
            ...data.data,
            categories: data.data.categories.map(cat => cat.id),
            preview: {
              src: data.data.cover
            }
          }

          this.blogTemplate = {
            ...data.data,
            content: JSON.parse(data.data.content)
          }
          applyPageStyles(data.data.css, this.subjectStyle)
          this.initUndoRedoSupport()
          this.$store.commit('blogEditor/EDIT_POST', this.post)
          this.$store.commit('blogEditor/EDIT_BLOG_TEMPLATE', this.blogTemplate)
        })
    } else {
      const main = this.templates.findIndex(t=> t.is_main)
      await this.selectTemplate(main !== -1 ? main : 1)
      this.initUndoRedoSupport()
    }
  },

  methods: {
    getTargetCSS () {
      return pageStylesToString(this.subjectStyle)
    },
    onSave (options = {}) {
      this.$store.commit('editor/SET_PAGE', 'page-settings')

      setTimeout(() => {
        if (!this.$refs.postSettingsTab.open) this.$refs.postSettingsTab.toggle()

        setTimeout(() => {
          this.$refs.postSettings.validate()
            .then(() => {
              this.loading = true
              this.$store.dispatch('font/prepareFonts', {}, {root: true})
                .then((fonts) => {
                  if (this.$route.params.post_id === 'new') {
                    axios.post(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts`, {
                      ...this.post,
                      ...options,
                      fromDate: toServerTime(this.post.fromDate),
                      post_date: this.post.post_date.length !== 0 ? toServerTime(this.post.post_date) : null,
                      content: JSON.stringify(this.blogTemplate.content),
                      css: pageStylesToString(this.subjectStyle),
                      fonts: fonts.googleFonts + fonts.customFonts
                    })
                      .then(({data}) => {
                        this.$swal({
                          icon: 'success',
                          title: 'Post successfully created!',
                          showConfirmButton: false,
                          timer: 1500
                        })

                        this.post.slug = data.data.slug
                        this.post.status_code = data.data.status_code

                        this.$router.push({name: '', params: {post_id: data.data.id}})
                      })
                      .finally(() => {
                        this.loading = false
                      })
                  } else {
                    axios.put(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/${this.$route.params.post_id}`, {
                      ...this.post,
                      ...options,
                      fromDate: toServerTime(this.post.fromDate),
                      post_date: toServerTime(this.post.post_date),
                      content: JSON.stringify(this.blogTemplate.content),
                      css: pageStylesToString(this.subjectStyle),
                      fonts: fonts.googleFonts + fonts.customFonts
                    })
                      .then(({data}) => {
                        this.$swal({
                          icon: 'success',
                          title: 'Post successfully updated!',
                          showConfirmButton: false,
                          timer: 1500
                        })

                        this.post.status_code = data.data.status_code
                      })
                      .finally(() => {
                        this.loading = false
                      })
                  }
                })
            })
            .catch(() => {
              document.querySelector('.scroll-wrapper .text-danger').closest('.form-group').scrollIntoView({behavior: 'smooth'})
            })
        })
      })
    },
    saveAsTemplate () {
      this.modalLoading = true

      this.takeScreenshot(document.getElementById('editor-container').querySelector('div > div'))
        .then((screen) => {
          axios.post(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/templates?template_type=single`, {
            template_type: 'single',
            content: JSON.stringify(this.blogTemplate.content),
            css: pageStylesToString(this.subjectStyle),
            name: this.layoutName,
            has_sidebar: this.settings.sidebar.enable,
            sidebar_right: this.settings.sidebar.right,
            hide_header: this.settings.hideHeader || false,
            hide_footer: this.settings.hideFooter || false,
            is_main: false,
            screen
          })
            .then(() => {
              this.$swal({
                icon: 'success',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: 'Saved!',
                showConfirmButton: false,
                timer: 1500
              })

              this.fetchTemplates()
                .then(() => {
                  // this.selectTemplate(2)
                })
            })
            .catch(() => {
              this.$swal({
                icon: 'error',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: 'Server Error.',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .finally(() => {
              this.modalLoading = false
              this.modal = false
            })
        })
    },
    async fetchTemplates () {
      this.loading = true
      return await axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/templates?template_type=single`)
        .then(({data}) => {
          this.templates = [...this.templates, ...data.data.data.filter(temp => temp.screen)]
        })
        .finally(() => {
          this.loading = false
        })
    },
    alertThenSelectTemplate (index){
      this.$swal({
        icon: 'warning',
        html: 'You are about to switch to another template. <br> Your changes will be lost. <br> Are you sure you want to proceed?',
        showConfirmButton: true,
        showCancelButton: true,
        focusConfirm: false,
        focusCancel:true,
        confirmButtonText: 'Yes, switch to the template'
      }).then((result) => {
        if(result.isConfirmed) this.selectTemplate(index)
      })
    },
    selectTemplate (index) {
      this.$store.commit('editor/SET_PAGE', '')

      if (!this.templates[index].css) {
        this.loading = true
        return axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/templates/${this.templates[index].id}`)
          .then(({data}) => {
            this.templates[index].content = JSON.parse(data.data.content)
            this.templates[index].css = data.data.css
            this.blogTemplate = {
              ...data.data,
              content: JSON.parse(data.data.content)
            }
            applyPageStyles(data.data.css, this.subjectStyle)
            this.initUndoRedoSupport()
          })
          .finally(() => {
            this.loading = false
          })
      }

      this.blogTemplate = JSON.parse(JSON.stringify(this.templates[index]))
      applyPageStyles(this.blogTemplate.css, this.subjectStyle)
    }
  }
}
</script>

<style scoped>
.social-icon {
  width: 35.44px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
</style>
