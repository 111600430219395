<template>
  <b-dropdown
    v-model="isDropdownVisible"
    variant="link"
    size="lg"
    toggle-class="text-decoration-none p-0 no-focus"
    no-caret
    menu-class="py-0"
    lazy
    right
    @show="updateLocalColor"
    @hidden="isDropdownVisible = false"
  >
    <template slot="button-content">
      <slot>
        <div
          class="pollite d-flex align-items-center justify-content-center"
          :class="{'pollite-small': !small}"
        >
          <div class="position-relative">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="1.83333"
                width="22"
                height="20.1667"
                fill="#C4C4C4"
              />
              <rect
                width="7"
                height="5.5"
                fill="#F9F9F9"
              />
              <rect
                x="7"
                width="8"
                height="5.5"
                fill="#DDDDDD"
              />
              <rect
                x="15"
                width="7"
                height="5.5"
                fill="#F9F9F9"
              />
              <rect
                y="16.5"
                width="7"
                height="5.5"
                fill="#E5E5E5"
              />
              <rect
                x="7"
                y="16.5"
                width="8"
                height="5.5"
                fill="#F9F9F9"
              />
              <rect
                x="15"
                y="16.5"
                width="7"
                height="5.5"
                fill="#E5E5E5"
              />
              <rect
                y="11"
                width="7"
                height="5.5"
                fill="#F9F9F9"
              />
              <rect
                x="7"
                y="11"
                width="8"
                height="5.5"
                fill="#DDDDDD"
              />
              <rect
                x="15"
                y="11"
                width="7"
                height="5.5"
                fill="#F9F9F9"
              />
              <rect
                y="5.5"
                width="7"
                height="5.5"
                fill="#DDDDDD"
              />
              <rect
                x="7"
                y="5.5"
                width="8"
                height="5.5"
                fill="#F9F9F9"
              />
              <rect
                x="15"
                y="5.5"
                width="7"
                height="5.5"
                fill="#DDDDDD"
              />
            </svg>
            <div
              class="pollite-backdrop"
              :style="{background: color}"
            />
          </div>
        </div>
      </slot>
    </template>

    <div>
      <chrome-picker
        ref="color-picker"
        :preset-colors="[]"
        :value="colors"
        @input="changeColor($event, 'input')"
      />

      <div
        class="vc-sketch-presets !pl-[18px] border-0"
        role="group"
        aria-label="A color preset, pick one to set as current color"
      >
        <i
          class="fa fa-eye-dropper cursor-pointer text-[14px] mr-2"
          @click="sampleColorFromScreen"
        />

        <div
          v-for="(c, index) in ['#1f3b9c', '#fcb045', '#1abc9c', '#2ecc71', '#9b59b6', '#3498db', '#34495e', '#e67e22', '#e74c3c', '#bdc3c7', '#ffffff', '#000000', ...getPalette(globalStyles.colors, 'palette')]"
          :key="index"
          :aria-label="'Color:' + c"
          class="vc-sketch-presets-color"
          :style="{background: c}"
          @click="handlePreset(c)"
        />

        <div
          class="vc-sketch-presets-color"
          @click="addNewColor"
        >
          <i class="fa fa-plus text-[10px] text-gray-500 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" />
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ColorPicker',

  props: {
    color: {
      required: true
    },
    small: {
      default: true
    }
  },

  data () {
    return {
      colors: {
        hex: this.color
      },
      mouseOver: false,
      isDropdownVisible: true
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    })
  },

  watch: {
    color () {
      this.colors =  {
        hex: this.color
      }
    }
  },

  methods: {
    async sampleColorFromScreen (abortController) {
      // eslint-disable-next-line no-undef
      const eyeDropper = new EyeDropper()
      try {
        const result = await eyeDropper.open({signal: abortController.signal})
        this.handlePreset(result.sRGBHex)
        return result.sRGBHex
      } catch (e) {
        return null
      }
    },
    addNewColor () {
      if (this.globalStyles.colors.palette && this.globalStyles.colors.palette.includes(this.color)) return
      this.globalStyles.colors.palette.push(this.color)
    },
    handlePreset (c) {
      this.$refs['color-picker'].handlePreset(c)
    },
    getPalette (obj, pollite) {
      if (!obj.hasOwnProperty(pollite)) {
        this.$set(obj, pollite, [])
      }
      return obj[pollite]
    },
    updateLocalColor () {
      this.colors.hex = this.color
    },
    clearSelection () {
      const focusableElement = document.querySelector('body')
      if (focusableElement) {
        focusableElement.focus()
      }
    },
    changeColor (event, emit) {
      this.colors.hex = event.hex
      this.colors.rgba = event.rgba

      this.updateLocalColor()
      this.$emit(emit, event)

      if (emit === 'change') {
        this.clearSelection()
        setTimeout(() => {
          if (window.getSelection) {
            if (window.getSelection().empty) {  // Chrome
              window.getSelection().empty()
            } else if (window.getSelection().removeAllRanges) {  // Firefox
              window.getSelection().removeAllRanges()
            }
          } else if (document.selection) {  // IE?
            document.selection.empty()
          }
        }, 100)
      }
    }
  }
}
</script>

<style lang="scss">
  .pollite.pollite-small {
    width: 26px;
    height: 26px;
  }

  .vc-sketch {
    box-shadow: none !important;

    .vc-sketch-presets {
      padding: 0px;
    }
  }
</style>
