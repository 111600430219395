<template>
  <div
    id="app"
    class="min-vh-100 flex flex-column w-full"
  >
    <programmatically-confirmation-dialog />
    <router-view />

    <preloader />
    <alt-preloader />
    <progress-loader />

    <div class="w-full" />

    <the-footer
      v-if="!['login','reset-password','sign-up', 'editor', 'modal.editor', 'mega-menu.editor', 'editor.post', 'module.blog','reactivate', 'welcome'].includes($route.name) && $route.name ? !$route.name.startsWith('editor.') : false"
      class="mt-auto"
    />
    <!--    <error-modal-->
    <!--      v-for="(error,index) in errorsList"-->
    <!--      :key="index"-->
    <!--      :error="error"-->
    <!--    />-->
  </div>
</template>

<script>
import _ from 'lodash'
import Preloader from './components/utils/Preloader'
import AltPreloader from './components/utils/AltPreloader'
import ProgressLoader from './components/utils/ProgressLoader'
import TheFooter from './layouts/Footer.vue'
import ProgrammaticallyConfirmationDialog from '@/components/editor/modals/ProgrammaticallyConfirmationDialog.vue'

export default {
  name: 'App',
  components: {ProgrammaticallyConfirmationDialog, ProgressLoader, AltPreloader, Preloader,TheFooter},
  computed: {
    errorsList () {
      return this.$store.state.notifications.errors
    }
  },
  created () {
    if (_.includes(['BUSINESS_PLUS_M', 'BUSINESS_PLUS_Y'], this.$store.state.auth.user.user_subscription?.plan.code)) {
      this.$store.state.editor.businessPlusSubscription = true
    } else {
      axios.get('https://api.estage.com/api/user/profile')
        .then(({data}) => {
          this.$store.state.editor.businessPlusSubscription = data.data.business_plus
        })
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        const $viewport = document.head.querySelector('[name="viewport"]')

        if (to.meta.viewport) {
          $viewport.setAttribute('content', 'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0')
        } else {
          $viewport.setAttribute('content', '')
        }
      }
    }
  },
  methods: {
    cors () {
      axios.post(`http://${process.env.VUE_APP_API_HOST}/api/auth/login`)
    }
  }
}
</script>

<style lang="scss">
[data-lastpass-root] {
  transform: translateX(100%);
}
</style>
