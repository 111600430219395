<template>
  <div class="dashboard-space dashboard-background min-vh-100 flex flex-col">
    <dashboard-header
      disabled
    />
    <div class="text-center flex flex-col grow justify-center font-[ProximaNova] py-[71px]">
      <div>
        <h1 class="text-[57px] font-bold mb-0">
          Account Closed
        </h1>
        <p
          v-if="getStatus('reason')"
          class="text-[19px] text-[#787878]  mb-[27px]"
        >
          Reason: {{ getStatus('reason') }}
        </p>
        <div
          v-if="getStatus('video_id')"
          class="h-[391px] max-h-[391px] w-[694px] max-w-[694px] mx-auto rounded-[4px] overflow-hidden relative"
        >
          <div
            style="padding:56.69% 0 0 0;"
          >
            <iframe
              :src="`https://player.vimeo.com/video/${getStatus('video_id')}?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479`"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              style="position:absolute;top:0;left:0;width:100%;height:100%;"
            />
          </div>
        </div>
        <p class="text-[19px] text-[#787878] mt-[32px]">
          Your Account, Projects and All Data Will Be <span class="text-black font-bold">Permanently Removed</span> <template v-if="user.user_subscription && user.user_subscription.data_deleted_at">
            In:
          </template>
        </p>
        <vac
          v-if="user.user_subscription && user.user_subscription.data_deleted_at"
          :end-time="user.user_subscription.data_deleted_at"
        >
          <template
            v-slot:process="{ timeObj }"
          >
            <div class="flex gap-[29px] justify-center text-white text-[45px] my-[40px]">
              <div
                v-for="(time, index) in [timeObj.d, timeObj.h, timeObj.m, timeObj.s]"
                :key="index"
                class="rounded-[5px] h-[70px] w-[70px] bg-[#333333]"
              >
                {{ time }}
              </div>
            </div>
          </template>
        </vac>
        <p class="text-[19px] text-[#787878] mb-[35px]">
          You can still re-activate your full account status account <br> before the "grace-period" timer runs out!
        </p>

        <button
          class="h-[63px] w-[298px] bg-[#4F83E3] hover:opacity-80 text-[18px] text-white rounded"
          @click="modal = true"
        >
          Reactivate Your Account
        </button>

        <reactivate-plan-modal 
          v-model="modal"
          :success="paymentSuccess"
          @submit="onReactivate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapState} from 'vuex'
import DashboardHeader from '@/layouts/dashboard/DashboardHeader.vue'
import ReactivatePlanModal from '@/components/editor/modals/reactivate-plan/ReactivatePlanModal.vue'
export default {
  name: 'Reactivate',
  components: {
    ReactivatePlanModal,
    DashboardHeader
  },
  data () {
    return {
      modal: false,
      paymentSuccess: false,
      paypalChecking: false,
      checkPPTimout: null,
      planCodes: [
        'BUSINESS_PLUS_M',
        'BUSINESS_PLUS_Y',
        'ESTAGE_SUB',
        'BUSINESS_Y'
      ],
      statuses: {
        payment_failed: {
          reason: 'payment failure',
          video_id: '897472426'
        },
        refund: {
          reason: 'payment refunded',
          video_id: '897473108'
        },
        canceled: {
          reason: 'account cancelation request',
          video_id: '897473962'
        },
        no_subscription: {
          reason: 'subscription not found'
        }
      }
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    userSubscriptionEmpty () {
      return !this.user.user_subscription && this.user?.projects_count > 0
    }
  },
  watch: {
    modal (newValue) {
      if(!newValue && this.paymentSuccess) {
        window.location = '/'
      }
    }
  },
  mounted () {
    if(this.checkIsAlreadyReactivated(this.user)) {
      return this.$router.push({name: 'dashboard'})
    }
    this.checkPayPalStatus()
  },
  beforeDestroy () {
    if(this.checkPPTimout) clearTimeout(this.checkPPTimout)
  },
  methods: {
    checkIsAlreadyReactivated (user) {
      if (this.userSubscriptionEmpty) {
        return false
      }
      else {
        return !(user.user_subscription &&
          _.includes(this.planCodes, user.user_subscription?.plan?.code) &&
          user.user_subscription?.status !== 'succeeded')
      }
    },
    getStatus (key) {
      const reason = this.userSubscriptionEmpty ? 'no_subscription' : this.user.user_subscription.reason
      return _.get(this.statuses[reason], key)
    },
    checkPayPalStatus () {
      const count = localStorage.getItem('pp-attempt-count')
      if(count === null) return
      if(count > 15) {
        localStorage.removeItem('pp-attempt-count')
        this.$swal({
          icon: 'error',
          iconColor: '#4F83E3',
          toast: true,
          position: 'top-right',
          title: 'PayPal Payment Declined.',
          text: 'Please try again later or let us know about your problem.',
          showConfirmButton: false,
          timer: 5000
        })
        this.modal = false
        this.paypalChecking = false
      }
      else {
        this.paypalChecking = true
        axios.get('api/auth/user')
          .then((result) => {
            if(this.checkIsAlreadyReactivated(result.data)) {
              localStorage.removeItem('pp-attempt-count')
              if(this.checkPPTimout) clearTimeout(this.checkPPTimout)
              this.paypalChecking = false
              this.paymentSuccess = true
            }
            else {
              this.checkPPTimout = setTimeout(() => {
                localStorage.setItem('pp-attempt-count', parseInt(count) + 1)
                this.checkPayPalStatus()
              }, 1000 * 10)
            }
          })
      }
    },
    onPayPalSuccess () {
      localStorage.setItem('pp-attempt-count', 0)
      this.checkPayPalStatus()
    },
    onReactivate ({pm_id, setLoading, plan}) {
      setLoading(true)
      axios.post('api/user/subscriptions/reactivate', {
        stripe_card_token: pm_id,
        user_hash: this.user.hash,
        plan: plan
      })
        .then(() => {
          this.paymentSuccess = true
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Payment Declined.',
            text: 'The card information was declined',
            showConfirmButton: false,
            timer: 5000
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }
}
</script>
<style>
  /* .radio-custom {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 17px;
    width: 17px;
    border: 2px solid #3D82EA;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
  }

  input[type="radio"].radio-custom:checked, input[type="radio"].radio-custom:focus {
    border: 2px solid #3D82EA;
    background-color: #3D82EA;
    box-shadow: inset 0 0 0 3px white!important;
  }
  input[type="radio"][disabled]{
    border: 2px solid #E2E5EC;
    background-color: #E2E5EC;
    box-shadow: inset 0 0 0 3px white!important;
  }
  .pm-custom-select-arrow{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGlkPSJsaW5lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMzE1NTE2IDAuMTcwODU0QzAuNTI5MTcyIC0wLjA1Njk1MTUgMC44NzU1NzggLTAuMDU2OTUxNSAxLjA4OTIzIDAuMTcwODU0TDUuMDc5MTkgNC40MjUwNEw5LjA2OTE0IDAuMTcwODU0QzkuMjgyOCAtMC4wNTY5NTE1IDkuNjI5MiAtMC4wNTY5NTE1IDkuODQyODYgMC4xNzA4NTRDMTAuMDU2NSAwLjM5ODY2IDEwLjA1NjUgMC43NjgwMDYgOS44NDI4NiAwLjk5NTgxMkw1LjQ2NjA1IDUuNjYyNDhDNS4yNTIzOSA1Ljg5MDI5IDQuOTA1OTggNS44OTAyOSA0LjY5MjMzIDUuNjYyNDhMMC4zMTU1MTYgMC45OTU4MTJDMC4xMDE4NTkgMC43NjgwMDYgMC4xMDE4NTkgMC4zOTg2NiAwLjMxNTUxNiAwLjE3MDg1NFoiIGZpbGw9IiMxRTFFMUUiLz4KPC9zdmc+Cg==')!important;
  }
  .pm-custom-select.v-select.custom-select .vs__selected{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
  } */
</style>
